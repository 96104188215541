import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';
import Style from "../constants/style";
import {ScreenSize} from "../constants/screensize";

const Container = styled.div`
	width: 90%;
	max-width: 700px;
	margin: 0 auto 30px auto;
	padding-top: 20px;
	text-align: center;
`;

const Header = styled.h2`
	text-align: center;
	@media (max-width: ${ScreenSize.phone}px) {
		width: 90%;
		margin: 20px auto;
		font-size: 20px;
	}
`;

const Subtitle = styled.h3`
	@media (max-width: ${ScreenSize.phone}px) {
		width: 90%;
		margin: 20px auto;
		text-align: center;	
		font-size: 16px;
	}
`;

const Input = styled.input`
	margin-top: 40px;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
	border: honeydew;
	display: block;
	width: calc(100% - 33px);
	padding: 16px;
	font-size: 18px;
	border-radius: 2px;
	outline: none;
`;

const SuggestionContainer = styled.div`
	width: 100%;
	margin-top: 10px;
	text-align: left;
`;

const Suggestion = styled.div`
	width: calc(100% - 33px);
	padding: 8px 16px;
	background: ${props => props.active ? Style.primaryGreen : 'white'};
	cursor: ${props => props.active ? 'pointer' : 'initial'};
	font-weight: ${props => props.active ? 600 : 400};
	font-size: 18px;
`;

class AddressContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false
		};
	}

	render() {
		return (
			<Container>
				<Header>
					What address are we setting this up at?
				</Header>
				<Subtitle>
					We just want to show up to the right place.
					<br />
					<br />
					Note that this does not have to be the same as the billing address.
				</Subtitle>
				<PlacesAutocomplete
					value={this.props.value}
					onChange={this.props.onChange}
					shouldFetchSuggestions={this.props.value.length > 3}
				>
					{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
						<div>
							<Input
								{...getInputProps({
									placeholder: 'Start typing setup address...'
								})}
							/>
							<SuggestionContainer>
								{loading && <div>Loading...</div>}
								{suggestions.map(suggestion => {
									return (
										<Suggestion {...getSuggestionItemProps(suggestion)} active={suggestion.active}>
											<span>{suggestion.description}</span>
										</Suggestion>
									);
								})}
							</SuggestionContainer>
						</div>
					)}
				</PlacesAutocomplete>
			</Container>
		);
	}
}

AddressContainer.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired
};

export default AddressContainer;
