import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import './CheckoutButtonStyle.css';
import StripeCheckout from 'react-stripe-checkout';
import STRIPE_PUBLISHABLE from '../constants/stripe';
import PAYMENT_SERVER_URL from '../constants/server';
import Style from "../constants/style";
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import {Utils} from "../utils/Utils";
import {Analytics} from "../utils/Analytics";
import moment from "moment";

const Container = styled.div`
	width: 90%;
	margin: auto;
`;

const ButtonWrapper = styled.div`
	width: 100%;
	max-width: 400px;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 250px;
	text-align: center;
`;

const Italic = styled.div`
	font-style: italic;
	padding-top: 5px;
`;

const StripeButton = styled.button`
	overflow: hidden;
	display: inline-block;
	border: 0;
	padding: 1px;
	text-decoration: none;
	box-shadow: rgba(0, 0, 0, 0.2) 0 1px 0;
	cursor: pointer;
	visibility: visible;
	user-select: none;
	width: 100%;
	max-width: 400px;
	margin: auto;

	border-radius: 25px;
	background: ${Style.primaryGreen} !important;
`;

const StripeSpan = styled.span`
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	position: relative;
	padding: 0 12px;
	display: block;
	height: 30px;
	font-weight: bold;
	box-shadow: rgba(255, 255, 255, 0.25) 0 1px 0 inset;
	min-height: 50px !important;
	font-size: 20px !important;
	line-height: 50px !important;
	border-radius: 25px;
	background: ${Style.primaryGreen} !important;
	color: ${Style.darkGreen} !important;
	text-shadow: none !important;
`;

const ErrorMessageLabel = styled.div`
	font-size: 30px;
	color: darkred;
	text-align: center;
`;

class CheckoutButton extends Component {
    render() {
        return (
            <Container>
	            {this.props.enabled ? this.renderCheckoutButton() : this.renderErrorMessage()}
            </Container>
        );
    }

    renderCheckoutButton = () => {
    	const fiveDaysAgo = this.props.setupDate.clone().subtract(5, 'd');
    	const isChargeDateOnOrBeforeToday = moment().diff(fiveDaysAgo, 'days') >= 0;

    	return (
    		<ButtonWrapper>
					{!isChargeDateOnOrBeforeToday &&
						<StripeCheckout
							name={'SEASONAL SETTER LLC'}
							description={'Holiday Package Deposit'}
							amount={this.props.deposit * 100}
							token={this.submitPaymentDeposit}
							currency={'USD'}
							zipCode={true}
							billingAddress={true}
							locale='auto'
							image='/logo.png'
							stripeKey={STRIPE_PUBLISHABLE}
						>
							<StripeButton className="stripe-button-el"
														onClick={() => Analytics.logCheckoutPressed(true)}>
								<StripeSpan className="stripe-button-el">Pay
									Deposit: &nbsp; {Utils.formatAsCurrency(this.props.deposit)}</StripeSpan>
							</StripeButton>
							<Italic>
								Remaining {Utils.formatAsCurrency(this.props.fullAmount - this.props.deposit)} will be charged
								on {this.props.setupDate.clone().subtract(5, 'd').format("MMM Do, YYYY")}.
							</Italic>
						</StripeCheckout>
					}
					{!isChargeDateOnOrBeforeToday &&
						<div>
							- OR -
						</div>
					}
					<StripeCheckout
						name={'SEASONAL SETTER LLC'}
						description={'Holiday Package Full Amount'}
						amount={this.props.fullAmount * 100}
						token={this.submitPaymentFullAmount}
						currency={'USD'}
						zipCode={true}
						billingAddress={true}
						locale='auto'
						image='/logo.png'
						stripeKey={STRIPE_PUBLISHABLE}
					>
						<StripeButton className="stripe-button-el"
													onClick={() => Analytics.logCheckoutPressed(false)}>
							<StripeSpan className="stripe-button-el">Pay In
								Full: &nbsp; {Utils.formatAsCurrency(this.props.fullAmount)}</StripeSpan>
						</StripeButton>
					</StripeCheckout>
				</ButtonWrapper>
	    );
    };

    renderErrorMessage = () => {
    	return (
			<ErrorMessageLabel>
				{this.props.invalidReason}
			</ErrorMessageLabel>
	    );
    };

    submitPaymentDeposit = (token) => {
    	this.submitPayment(token, true);
		};

    submitPaymentFullAmount = (token) => {
			this.submitPayment(token, false);
		};

    submitPayment = (token, isDeposit) => {
    	console.log("TOKEN");
    	console.log(token);
			const params = {
				stripeToken: token.id,
				stripeEmail: token.email,
				stripeBillingName: token.card.name,
				stripeBillingAddressStreet: token.card.address_line1,
				stripeBillingAddressCity: token.card.address_city,
				isDeposit: isDeposit,
				isReorder: false,
				isCustom: true,
				options: this.props.options
			};

			this.props.submittingStatusChanged(true);

			Analytics.logCheckoutSubmitted();

	    axios.post(PAYMENT_SERVER_URL, params)
				.then(this.successPayment)
				.catch(this.errorPayment);
    };

    successPayment = () => {
			Analytics.logCheckoutSuccess(this.tokenId, this.isDeposit, this.amountPaid);
			this.props.submittingStatusChanged(false);
	    window.location.href = "https://seasonalsetter.com#thankyou";
    };

    errorPayment = (error) => {
    	console.log("Error with payment:");
    	console.log(error);
			Analytics.logCheckoutFailed(error);
			this.props.submittingStatusChanged(false);
    	alert("Oops! Your payment didn't go through. Please contact us directly at: peter@seasonalsetter.com");
    };
}

CheckoutButton.propTypes = {
	submittingStatusChanged: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
	setupDate: momentPropTypes.momentObj,
	fullAmount: PropTypes.number.isRequired,
	deposit: PropTypes.number.isRequired,
	invalidReason: PropTypes.string.isRequired
};

export default CheckoutButton;
