import React, {Component} from 'react';
import styled from 'styled-components';
import Style from "../constants/style";

const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: 30px;
`;

const TextBox = styled.input`
	font-size: 30px;
	text-align: center;
	border: 2px solid ${Style.transparentBlack};
	border-radius: 30px;
`;

class InputBox extends Component {
	constructor(props) {
		super(props);

		this.input = React.createRef();
	}

	render() {
		return (
			<Container>
				<TextBox
					{...this.props}
					ref={this.input}
				/>
			</Container>
		);
	}

	focus = () => {
		this.input.current.focus();
	}
}

InputBox.propTypes = {

};

export default InputBox;
