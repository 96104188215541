import ReactGA  from 'react-ga';
const isAnalyticsAllowed = process.env.NODE_ENV === 'production';

const GoogleAnalyticsWrapper = {
	init: () =>  {
		ReactGA.initialize('UA-150294374-5');
		ReactGA.plugin.require('ec');
	},

	// ARGS:
	//  event: object {
	//    category: string
	//  	action: string
	//    label: string (optional)
	//    value: int (optional)
	//    nonInteraction: bool (optional)
	//  }
	logEvent: (event) => {
		if (!isAnalyticsAllowed) {
			console.log("Log event:");
			console.log(event);
			return;
		}

		ReactGA.event(event);
	},

	// ARGS:
	//  pagename: string
	logPageView: (pagename) => {
		const pageToLog = pagename || "/";

		if (!isAnalyticsAllowed) {
			console.log("Log pageview:");
			console.log(pageToLog);
			return;
		}

		ReactGA.pageview(pageToLog);
	},

	logEcommercePurchase: (orderId, amountPaid) => {
		if (!isAnalyticsAllowed) {
			console.log("Log ecommerce:");
			console.log(orderId, amountPaid);
			return;
		}

		ReactGA.plugin.execute('ec', 'addProduct', {
			name: 'Re-Order Holiday Glow Package 2019',
			price: amountPaid
		});

		ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
			id: orderId,
			revenue: amountPaid
		});
	},
};

export const Analytics = {
	init: () => {
		if (isAnalyticsAllowed) {
			GoogleAnalyticsWrapper.init();
		}
	},

	logCurrentURL: () => {
		GoogleAnalyticsWrapper.logPageView(window.location.pathname + window.location.search);
	},

	// ARGS:
	//  pagename: string
	logPageView: (pagename) => {
		GoogleAnalyticsWrapper.logPageView(pagename);
	},

	// ARGS:
	//  isSetupDate: bool
	//  dateAsString: string
	logDateSelected: (isSetupDate, dateAsString) => {
		GoogleAnalyticsWrapper.logEvent({
			category: "Order Building",
			action: "Date Selected",
			label: (isSetupDate ? "Setup: " : "Teardown: ") + dateAsString
		});
	},

	// ARGS:
	//  name: string
	//  didSucceed: bool
	logSearchStarted: (name) => {
		GoogleAnalyticsWrapper.logEvent({
			category: "Order Building",
			action: "Search Order",
			label: name
		});
	},

	// ARGS:
	//  isDeposit: bool
	logCheckoutPressed: (isDeposit) => {
		GoogleAnalyticsWrapper.logEvent({
			category: "Checkout",
			action: "Pay Button Clicked",
			label: isDeposit ? "Deposit" : "Full Amount"
		});
	},

	logCheckoutSubmitted: () => {
		GoogleAnalyticsWrapper.logEvent({
			category: "Checkout",
			action: "Order Submitted",
			nonInteraction: true
		});
	},

	// ARGS:
	//  orderId: string -- stripe customer id
	//  isDeposit: bool
	//  amountPaid: int -- in dollars
	logCheckoutSuccess: (orderId, isDeposit, amountPaid) => {
		GoogleAnalyticsWrapper.logEcommercePurchase(orderId, amountPaid);
		GoogleAnalyticsWrapper.logEvent({
			category: "Checkout",
			action: "Order Success",
			label: orderId + ": " + (isDeposit ? "Deposit" : "Full Amount") + ", $" + amountPaid,
			nonInteraction: true
		});
	},

	// ARGS:
	//  reason: string
	logCheckoutFailed: (reason) => {
		GoogleAnalyticsWrapper.logEvent({
			category: "Checkout",
			action: "Order Failed",
			label: reason,
			nonInteraction: true
		});
	}
};
