import React, {Component} from 'react';
import styled from 'styled-components';
import CheckoutButton from "./components/CheckoutButton";
import TermsAndConditions from "./components/TermsAndConditions";
import DateContainer from "./components/DateContainer";
import moment from "moment";
import Button from "./components/Button";
import {FormCodes} from "./constants/errorcodes";
import {BarLoader} from 'react-spinners';
import Style from "./constants/style";
import WelcomeHeader from "./components/WelcomeHeader";
import {Utils} from "./utils/Utils";
import {Analytics} from "./utils/Analytics";
import InputBox from "./components/InputBox";
import LargeTextBox from "./components/LargeTextBox";
import AddressContainer from "./components/AddressContainer";
import axios from "axios";
import {SEARCH_SERVER_URL} from "./constants/server";

const OrderPageContainer = styled.div`
`;

const CheckoutContainer = styled.div`
	padding-top: 50px;
	right: 0;
	width: ${props => props.fullscreen ? 100 : 30}vw;
	height: ${props => props.fullscreen ? '' : 'calc(100vh - 274px)'};
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: ${props => props.fullscreen ? '#F8F8F8' : 'white'};
	border-top: 1px solid gray;
	border-bottom: ${props => props.fullscreen ? 'none' : '1px solid gray'};
	z-index: ${props => props.fullscreen ? 1000 : 2000};
	padding-bottom: ${props => props.fullscreen ? '50' : '0'}vh;
`;

const MarginAuto = styled.div`
	width: 160px;
	padding: ${props => props.padding || 0}px;
	margin: auto;
`;

const LoadingOverlay = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	background: ${Style.transparentBlack};
	z-index: 5000;
	color: white;
	font-size: 48px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
`;

const BarLoadingContainer = styled.div`
	margin-top: 50px;
	width: 100%;
`;

const Header = styled.div`
	font-size: ${props => props.large ? 40 : 20}px;
	font-weight: 800;
	text-align: center;
`;

const Options = styled.div`
	text-align: center;
	margin: auto;
	padding-top: 20px;
	font-size: 24px;
`;

const TotalCost = styled.div`
	padding: 20px;
	font-size: 36px;
	font-weight: 800;
	color: darkgreen;
	text-align: center;
`;

const LookingForNameContainer = styled.div`
	padding-top: 100px;
`;

const ButtonContainer = styled.div`
	width: 90%;
	max-width: 500px;
	margin: auto;
	padding-top: 40px;
`;

const ErrorLabel = styled.div`
	padding-top: 30px;
	font-size: 20px;
	font-weight: ${props => props.red ? 800 : 400};
	color: ${props => props.red ? 'red' : 'black'};
	text-align: center;
`;

export default class App extends Component {
	constructor(props) {
		super(props);

		this.lastNameRef = React.createRef();

		const earliestDate = moment.max([moment().add(7, 'd'), moment('2022-12-07')]);

		this.state = {
			lookingForName: true,
			waitingOnAPIResponse: false,
			termsAccepted: false,
			comments: "",
			setupAddress: "",
			setupDate: earliestDate,
			teardownDate: moment('2023-01-04'),
			submitting: false,
			showingError: false,
			selectedName: {name: "", price: 0},
			firstName: "",
			lastName: "",
			options: ""
		}
	}

	componentWillMount() {
		Analytics.logPageView();
	}

	render() {
		const subtotal = this.state.selectedName.price;
		// const tax = Math.ceil(subtotal * 0.07);
		const totalPrice = subtotal;

		const formValidityCheck = this.isFormValid();

		const issueResolver = (
			<MarginAuto padding={40}>
				<Button onClick={() => this.resolveIssueFor(formValidityCheck)}>
					Resolve
				</Button>
			</MarginAuto>
		);

		const lookingForName = (
			<LookingForNameContainer>
				<Header>
					Enter your name to look up your order:
				</Header>
				<InputBox
					value={this.state.firstName}
					placeholder="First Name"
					onChange={(event) => this.setState({firstName: event.target.value})}
					onKeyDown={this.keyDownFirstName}
				/>
				<InputBox
					ref={this.lastNameRef}
					value={this.state.lastName}
					placeholder="Last Name"
					onChange={(event) => this.setState({lastName: event.target.value})}
					onKeyDown={this.keyDownLastName}
				/>
				<ButtonContainer>
					<Button onClick={this.searchForLastYearOrder}>
						Search
					</Button>
					{this.state.showingError ? (
						<div>
							<ErrorLabel red>
								Could not find your order.
								<br/>
								Please make sure you typed it correctly.
							</ErrorLabel>
							<ErrorLabel>
								If you're still having issues,
								<br/>
								contact us directly at:
								<br/>
								<a href="mailto:contact@seasonalsetter.com">
									contact@seasonalsetter.com
								</a>
							</ErrorLabel>
						</div>
					) : null}
				</ButtonContainer>
			</LookingForNameContainer>
		);

		const loadingOverlay = this.state.submitting || this.state.waitingOnAPIResponse ? (
			<LoadingOverlay>
				<div>
					{this.state.submitting ? 'Submitting' : 'Searching'}, please wait.
				</div>
				<BarLoadingContainer>
					<BarLoader
						css={{margin: 'auto'}}
						sizeUnit={"px"}
						height={7}
						width={250}
						color={"white"}
						loading={this.state.submitting}
					/>
				</BarLoadingContainer>
			</LoadingOverlay>
		) : null;

		return (
			<OrderPageContainer>
				{loadingOverlay}
				<WelcomeHeader/>
				{this.state.lookingForName ? lookingForName : (
					<CheckoutContainer fullscreen={true}>
						<Header>
							Ordering for:
						</Header>
						<Header large>
							{this.state.selectedName.name}
						</Header>
						<Options>
							<Header>
								With selected options:
							</Header>
							{this.state.selectedName.options.map(row =>
								<div key={row}>
									{row}
								</div>
							)}
						</Options>
						<DateContainer setupDate={this.state.setupDate} teardownDate={this.state.teardownDate} onSetupDateChanged={(date) => this.setState({setupDate: date})} onTeardownDateChanged={(date) => this.setState({teardownDate: date})} />
						{/*<InlineContainer>*/}
						{/*	<Header left>*/}
						{/*		Subtotal:*/}
						{/*	</Header>*/}
						{/*	<InlineContentRight>*/}
						{/*		{Utils.formatAsCurrency(subtotal)}*/}
						{/*	</InlineContentRight>*/}
						{/*</InlineContainer>*/}
						{/*<InlineContainer>*/}
						{/*	<Header left>*/}
						{/*		Tax:*/}
						{/*	</Header>*/}
						{/*	<InlineContentRight>*/}
						{/*		{Utils.formatAsCurrency(tax)}*/}
						{/*	</InlineContentRight>*/}
						{/*</InlineContainer>*/}
						<br/><br/>
						<AddressContainer value={this.state.setupAddress} onChange={this.handleSetupAddressChange}/>
						<LargeTextBox title="Additional Comments? (Optional)" placeholder="Do you have any additional comments or things that we should know about? Be as specific as you'd like. We don't like word limits." value={this.state.comments} onChange={this.onCommentsChanged}/>
						<Header>
							Which brings your total this year to:
						</Header>
						<TotalCost>
							{Utils.formatAsCurrency(totalPrice)}
						</TotalCost>
						<Header>
							Please re-read the Terms and Conditions, since they are different this year.
						</Header>
						<TermsAndConditions accepted={this.state.termsAccepted} onCheckChanged={(status) => this.setState({termsAccepted: status})}/>
						<CheckoutButton invalidReason={formValidityCheck.reason} enabled={formValidityCheck.valid} options={this.state} deposit={totalPrice/5} fullAmount={totalPrice} setupDate={this.state.setupDate} submittingStatusChanged={status => this.setState({submitting: status})}/>
						{!formValidityCheck.valid ? issueResolver : null}
					</CheckoutContainer>
				)}
			</OrderPageContainer>
		);
	}

	searchForLastYearOrder = () => {
		const firstName = this.state.firstName.trim();
		const lastName = this.state.lastName.trim();
		const trimmedName = (firstName + " " + lastName).trim();
		const nameToFind = trimmedName.toLowerCase();

		Analytics.logSearchStarted(nameToFind);

		this.setState({
			waitingOnAPIResponse: true
		});

		const params = {
			lookingForName: nameToFind
		};

		axios.post(SEARCH_SERVER_URL, params)
			.then(this.successSearch)
			.catch(this.errorSearch);
	};

	successSearch = (response) => {
		const selectedName = {
			name: response.data["Name"],
			price: Number(response.data["Price"]),
			options: response.data["Options"].split("\n")
		};

		this.setState({
			lookingForName: false,
			waitingOnAPIResponse: false,
			showingError: false,
			selectedName: selectedName
		});
	};

	errorSearch = (error) => {
		console.log("Error with search:");
		console.log(error);
		this.setState({
			waitingOnAPIResponse: false,
			showingError: true
		});
	};

	keyDownFirstName = (event) => {
		if (event.keyCode === 13) {
			this.lastNameRef.current.focus();
		}
	};

	keyDownLastName = (event) => {
		if (event.keyCode === 13) {
			this.searchForLastYearOrder();
		}
	};

	handleSetupAddressChange = setupAddress => {
		this.setState({ setupAddress });
	};

	onCommentsChanged = (newComments) => {
		this.setState({
			comments: newComments
		});
	};

	resolveIssueFor = ({code}) => {
		switch (code) {
			case FormCodes.TERMS:
				this.setState({termsAccepted: true});
				break;
			case FormCodes.HOUSE_SIZE:
				this.changeToSectionAtIndex(0);
				break;
			default:
				break;
		}
	};

	isFormValid = () => {
		if (!this.state.termsAccepted) {
			return {valid: false, reason: "You must accept the Terms and Conditions before checking out.", code: FormCodes.TERMS};
		}

		return {valid: true, reason: ""};
	};
}
