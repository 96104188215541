import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Style from "../constants/style";

const Container = styled.div`
	width: 90%;
	margin: 100px auto 100px auto;
	max-width: 800px;
`;

const TextArea = styled.textarea`
	border-radius: ${Style.borderRadius}px;
	width: 100%;
  max-width: calc(100% - 19px) !important;
  height: 100px;
  border: 2px solid black;
  padding: 5px 5px 5px 10px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
`;

class LargeTextBox extends Component {
	render() {
		return (
			<Container>
				<h3>{this.props.title}</h3>
				<TextArea {...this.props} onChange={(e) => this.props.onChange(e.target.value)}/>
			</Container>
		);
	}
}

LargeTextBox.propTypes = {
	title: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired
};

export default LargeTextBox;
