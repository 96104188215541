import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Terms } from '../constants/terms';
import ReactMarkdown from "react-markdown";
import {ScreenSize} from "../constants/screensize";

const Container = styled.div`
	width: 90%;
	max-width: 800px;
	margin: 50px auto;
`;

const TermsContainer = styled.div`
	height: 320px;
	overflow: scroll;
	border: 1px solid grey;
	background: white;
	padding: 20px;
	text-align: justify;
	white-space: pre-line;
`;

const CheckboxContainer = styled.div`
	display: flex;
	padding: 40px 0;
	font-size: larger;
	align-items: center;
	
	:hover {
		cursor: pointer;
	}
`;

const Input = styled.input`
	width: 30px;
	transform: scale(2);
	
	@media (max-width: ${ScreenSize.tablet}px) {
		width: 60px;
	}
`;

const Label = styled.div`
	padding-left: 20px;
`;

class TermsAndConditions extends Component {
	constructor(props) {
		super(props);

		this.inputRef = React.createRef();
	}

	render() {
		return (
			<Container>
				<TermsContainer>
					{<ReactMarkdown source={Terms} />}
				</TermsContainer>
				<CheckboxContainer onClick={this.onClick}>
					<Input checked={this.props.accepted} ref={this.inputRef} type='checkbox' onChange={(e) => this.props.onCheckChanged(e.target.checked)}/>
					<Label><strong>Yes, I agree to the terms and conditions above.</strong> <em>(You can also find a copy of the Terms and Conditions on the main website at any time from seasonalsetter.com)</em></Label>
				</CheckboxContainer>
			</Container>
		);
	}

	onClick = (e) => {
		console.log(!this.inputRef.current.checked);
		this.props.onCheckChanged(!this.inputRef.current.checked);
		e.stopPropagation();
	};
}

TermsAndConditions.propTypes = {
	accepted: PropTypes.bool.isRequired,
	onCheckChanged: PropTypes.func.isRequired
};

TermsAndConditions.defaultProps = {
	accepted: false
};

export default TermsAndConditions;
