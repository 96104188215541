import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './components/Calendar/CustomCalendarStyles.scss';
import { library } from '@fortawesome/fontawesome-svg-core'
import {faHome, faTree, faShoppingCart, faCalendar, faSnowman, faAward, faLightbulb, faDharmachakra, faChevronUp, faCheck} from '@fortawesome/free-solid-svg-icons';
import {Analytics} from "./utils/Analytics";

library.add(faHome, faTree, faShoppingCart, faCalendar, faSnowman, faAward, faLightbulb, faDharmachakra, faChevronUp, faCheck);

Analytics.init();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
