import React from 'react';
import moment from 'moment';
import omit from 'lodash/omit';

import { SingleDatePicker } from 'react-dates';

import { SingleDatePickerPhrases } from './calendarPhrases';
import { SingleDatePickerShape } from 'react-dates';
import { HORIZONTAL_ORIENTATION, ANCHOR_LEFT } from './calendarConstants';
import isInclusivelyAfterDay from './calendarInclusive';

const propTypes = {
	...omit(SingleDatePickerShape, [
		'date',
		'onDateChange',
		'focused',
		'onFocusChange',
	]),
};

const defaultProps = {
	// input related props
	id: 'date',
	placeholder: 'Date',
	disabled: true,
	required: false,
	screenReaderInputMessage: '',
	showClearDate: false,
	showDefaultInputIcon: false,
	customInputIcon: null,
	block: false,
	small: false,
	regular: false,
	verticalSpacing: undefined,
	keepFocusOnInput: false,

	// calendar presentation and interaction related props
	renderMonthText: null,
	orientation: HORIZONTAL_ORIENTATION,
	anchorDirection: ANCHOR_LEFT,
	horizontalMargin: 0,
	withPortal: false,
	withFullScreenPortal: false,
	initialVisibleMonth: null,
	numberOfMonths: 1,
	keepOpenOnDateSelect: false,
	reopenPickerOnClearDate: false,
	hideKeyboardShortcutsPanel: true,
	isRTL: false,

	// navigation related props
	navPrev: null,
	navNext: null,
	onPrevMonthClick() {},
	onNextMonthClick() {},
	onClose() {},

	// day presentation and interaction related props
	renderCalendarDay: undefined,
	renderDayContents: null,
	enableOutsideDays: false,
	isDayBlocked: () => false,
	isOutsideRange: day => !isInclusivelyAfterDay(day, moment().add(7, 'd')),
	isDayHighlighted: () => {},

	// internationalization props
	displayFormat: () => moment.localeData().longDateFormat('LL'),
	monthFormat: 'MMMM YYYY',
	phrases: SingleDatePickerPhrases,
};

class SingleDatePickerWrapper extends React.Component {
	render() {
		return (
			<SingleDatePicker
				{...this.props}
				id="date_input"
				focused={true}
				onFocusChange={() => {}}
			/>
		);
	}
}

SingleDatePickerWrapper.propTypes = propTypes;
SingleDatePickerWrapper.defaultProps = defaultProps;

export default SingleDatePickerWrapper;
