import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from "moment";
import SingleDatePickerWrapper from "./Calendar/SingleDatePickerWrapper";
import CalendarPhrases from "./Calendar/calendarPhrases";
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import momentPropTypes from 'react-moment-proptypes';
import Style from "../constants/style";
import {Analytics} from "../utils/Analytics";
import {ScreenSize} from "../constants/screensize";

const Container = styled.div`
	width: 90%;
	max-width: 700px;
	display: flex;
	justify-content: space-around;
	margin: auto;
	padding-top: 50px;
	flex-direction: row;

	@media (max-width: ${ScreenSize.tablet}px) {
		flex-direction: column;
	}
`;

const CalendarContainer = styled.div`
	width: 318px;
	height: 480px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
`;

const CalendarTitle = styled.div`
	font-size: 24px;
	padding-bottom: 30px;
	color: ${Style.darkGreen};
`;

class DateContainer extends Component {
	constructor(props) {
		super(props);

		const earliestDate = moment.max([moment().add(7, 'd'), moment('2023-11-01')]);

		this.state = {
			earliestPossibleSetupDate: earliestDate,
			earliestPossibleTeardownDate: moment('2024-01-04'),
			lastPossibleSetupDate: moment('2023-12-18'),
			lastPossibleTeardownDate: moment('2024-01-31'),
			blockedDays: [
				moment('2023-12-16').format('YYYY-MM-DD'),
				moment('2023-12-15').format('YYYY-MM-DD'),
				moment('2023-12-08').format('YYYY-MM-DD'),
				moment('2023-12-01').format('YYYY-MM-DD'),
				moment('2023-11-24').format('YYYY-MM-DD'),
				moment('2023-11-23').format('YYYY-MM-DD'),
				moment('2023-11-03').format('YYYY-MM-DD'),
				moment('2023-11-10').format('YYYY-MM-DD'),
				moment('2023-11-17').format('YYYY-MM-DD'),
				moment('2023-01-20').format('YYYY-MM-DD'),
				moment('2023-11-21').format('YYYY-MM-DD'),
				moment('2023-11-18').format('YYYY-MM-DD'),
				moment('2024-01-08').format('YYYY-MM-DD'),
				moment('2024-01-07').format('YYYY-MM-DD'),
				moment('2024-01-09').format('YYYY-MM-DD'),
				moment('2024-01-10').format('YYYY-MM-DD'),
				moment('2024-01-11').format('YYYY-MM-DD'),
				moment('2024-01-12').format('YYYY-MM-DD'),
				moment('2024-01-13').format('YYYY-MM-DD'),
				moment('2024-01-14').format('YYYY-MM-DD'),
				moment('2024-01-15').format('YYYY-MM-DD'),
				moment('2024-01-17').format('YYYY-MM-DD'),
			]
		}
	}

	render() {
		return (
			<Container>
				<CalendarContainer>
					<CalendarTitle>Guaranteed Setup Date:</CalendarTitle>
					<SingleDatePickerWrapper
						id="startdate"
						date={this.props.setupDate}
						isDayBlocked={day => this.state.blockedDays.includes(day.format('YYYY-MM-DD'))}
						isDayHighlighted={function noRefCheck() {}}
						onNextMonthClick={function noRefCheck() {}}
						onPrevMonthClick={function noRefCheck() {}}
						onDateChange={this.setupDateChanged}
						isOutsideRange={day => !isInclusivelyAfterDay(day, this.state.earliestPossibleSetupDate) || !isInclusivelyBeforeDay(day, this.state.lastPossibleSetupDate)}
						phrases={CalendarPhrases}
						placeholder="Start Date"
					/>
				</CalendarContainer>
				<CalendarContainer>
					<CalendarTitle>Guaranteed Teardown Date:</CalendarTitle>
					<SingleDatePickerWrapper
						id="teardowndate"
						date={this.props.teardownDate}
						isDayBlocked={day => this.state.blockedDays.includes(day.format('YYYY-MM-DD'))}
						isDayHighlighted={function noRefCheck() {}}
						onNextMonthClick={function noRefCheck() {}}
						onPrevMonthClick={function noRefCheck() {}}
						onDateChange={this.teardownDateChanged}
						isOutsideRange={day => !isInclusivelyAfterDay(day, this.state.earliestPossibleTeardownDate) || !isInclusivelyBeforeDay(day, this.state.lastPossibleTeardownDate)}
						phrases={CalendarPhrases}
						placeholder="Teardown Date"
					/>
				</CalendarContainer>
			</Container>
		);
	}

	setupDateChanged = (date) => {
		const dateAsString = date.format('YYYY-MM-DD');
		Analytics.logDateSelected(true, dateAsString);
		this.props.onSetupDateChanged(date);
	};

	teardownDateChanged = (date) => {
		const dateAsString = date.format('YYYY-MM-DD');
		Analytics.logDateSelected(false, dateAsString);
		this.props.onTeardownDateChanged(date);
	};
}

DateContainer.propTypes = {
	setupDate: momentPropTypes.momentObj,
	teardownDate: momentPropTypes.momentObj,
	onSetupDateChanged: PropTypes.func.isRequired,
	onTeardownDateChanged: PropTypes.func.isRequired
};

export default DateContainer;
