import React, { Component } from 'react';
import styled from 'styled-components';
import { ScreenSize } from '../constants/screensize';

const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	border-bottom: 1px solid gray;
	flex-direction: row;
	
	@media (max-width: ${ScreenSize.phone}px) {
		flex-direction: column;
	}
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

const HeaderLabel = styled.h1`
	font-size: 28px;
	font-weight: 800;
	
	@media (max-width: 800px) {
		font-size: 18px;
	}
`;

const Logo = styled.img`
	position: absolute;
	max-width: 50px; 
	left: 50px;
	
	@media (max-width: ${ScreenSize.phone}px) {
		position: inherit;
	}
`;

export default class WelcomeHeader extends Component {
	render() {
		return (
			<Container>
				<Logo src='logo.png' />
				<TextContainer>
					<HeaderLabel>
						Order Your Seasonal Setter Package
					</HeaderLabel>
				</TextContainer>
			</Container>
		);
	}
}
